html {
    font-size: 100%;
}

body {
    font-size: 1rem;
}

.modal-dialog{
    min-width: 90%;

    #frame{
      width: 100%;
      height: 85vh;

    }

    .modal-content{
      height: auto !important;
    }


}

.main-container {
    margin-top: 50px;
}

ul.promotions {
    margin-top: 10px;

    a.active {
        background-color: #eee;
    }
}

li.promotion {
    a {
        width: 100%;
    }
    span, a {
        display:inline-block;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    span {
        font-size: 1em;
        padding: 4px;
    }
}

.student-card {
    img{
      max-height: 200px;
    }
    .caption {
      text-align: center;
      height: 60px;
      overflow: hidden;
    }
}

/*-- Student --*/
.links {
    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li.websites {
        display: inline-block;

        ul {
            display: inline-block;

            li.website {
                display: inline-block;
                padding-right: 3px;
            }

            li.website:after {
                content: ',';
            }

            li.website:last-child:after{
                content: '';
            }

        }
    }
}


/*-- Artwork --*/

.artwork-card {
    overflow: hidden;

    span.title {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }
}

.flag {
    height: 18px;
}

.nav-tabs {
    margin-top: 10px;
    a {
      cursor: pointer;
    }
    h3 {
        display: block;
        float: left;
        line-height: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        margin-top: 0px;
        margin-bottom: 0;
    }
    li.admin:not(.active) a{
      background-color: #337ab7;
      color: white;
    }
}

div.api-field {
    margin-bottom: 20px;;
}

.artwork-about .tab-content {
    padding-top: 10px;
}

.lightbox-image-container {
  video{
    width: 100%
  }
}

/* header */
.navbar-brand {
    img {
        margin-top: -4px;
    }
}
.navbar {

  #formLogin {
    padding: 15px;
    min-width:300px;
  }

  .firstname{
    text-transform: capitalize;
  }

}

.pointer, a[ng-click], a[ui-sref] {
  cursor: pointer;
}

.header {
    display: block;
    height: 200px;
    position: relative;

    .overlay {
        background-color: none;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 2px, rgba(0,0,0,.2) 2px, rgba(0,0,0,.2) 4px);

        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
    }

    img {
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        z-index: 0;
    }

    h1 {
        position: absolute;

        left: 10px;
        bottom: 40px;
        z-index: 2;
        color: white;
        background-color: black;
        padding: 3px;
        font-weight: bold;
    }
    h2 {
        position: absolute;
        z-index: 2;

        left: 15px;
        bottom: 10px;
        z-index: 2;
        color: white;
        background-color: black;
        padding: 3px;
        font-weight: bold;
        font-size: 1.4em;

    }
}




.nature {

    &:first-letter {
        text-transform: uppercase;
    }
    ul.genres{
      display: inline;
      margin-left: 0px;

      li:after {
          content: ',';
      }

      li:last-child:after{
          content: '';
      }

    }

    ul.authors {
        display: inline-block;
        list-style-type: none;
        padding-left: 0;

        li:after {
            content: ',';
        }

        li:last-child:after{
            content: '';
        }

        .lastname {
            text-transform: uppercase;
        }
    }
    .type{
      &:before{
          content: " - "
      }
      text-transform: capitalize;
    }


}

.technic {

  ul {

    li:nth-child(n+2):before {
       /* tous sauf premier */
      content:"/ ";
    }

    li.duration {
      // background-color: #666;
      // color: white;
      //padding: 0;
    }

  }

}

.quicksearch {
    i.loading-glyph {
        color: white;
        padding-left: 5px;
    }
    ul {
        margin: 0px;
        padding: 0px;
        max-width: 30%;

        li {
            a {
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 5px;
                padding-top: 5px;
            }
        }
    }
}

.centered{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon{
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display:inline-block;
  &.icon-idcard{
    background-image: url(../images/candidature/icons/ID_light.svg);
    &.active, &:hover{
      background-image: url(../images/candidature/icons/ID_dark.svg);
    }
  }
  &.icon-graduation{
    background-image: url(../images/candidature/icons/cursus_light.svg);
    &.active, &:hover{
      background-image: url(../images/candidature/icons/cursus_dark.svg);
    }
  }
  &.icon-folder{
    background-image: url(../images/candidature/icons/portfolio_light.svg);
    &.active, &:hover{
      background-image: url(../images/candidature/icons/portfolio_dark.svg);
    }
  }
  &.icon-arrows{
    background-image: url(../images/candidature/icons/intentions_light.svg);
    &.active, &:hover{
      background-image: url(../images/candidature/icons/intentions_dark.svg);
    }
  }
  &.icon-check{
    background-image: url(../images/candidature/icons/summary_light.svg);
    &.active, &:hover{
      background-image: url(../images/candidature/icons/summary_dark.svg);
    }
  }

}

.no-gutter {
    padding-left: 0 !important;
    padding-right: 0 !important;
}


// Candidature


// FLAGS ICON
/* Change icon path */
$flag-icon-css-path: '../images/flags' !default;
@import "bower_components/flag-icon-css/sass/flag-icon";


body.fr {
  .fr{
    display: inline-block;
  }
  .en{
    display: none;
  }
}
body.en {
  .en{
    display: inline-block;
  }
  .fr{
    display: none;
  }
}

.red{
  color:#d0021b;
}
.green{
  color:#b8e986;
}

.additionnalInfo {
    color: #333;
    font-size: 9pt;
  }


/* animation Shake */
@keyframes shake {
  10%,30%,50%,70%,90% { transform: rotate(20deg);}
  0%,20%,40%,60%,80%,100% { transform: rotate(-20deg);}
}



/*

  DARK THEME

*/
$dark-bg-color: #333;
$dark-item-bg-color: #666;


body.darktheme {
  background-color: $dark-bg-color;
  color:#BBB;
  div[class*="dropdown"]{
    @extend body.darktheme;
  }
  .thumbnail{
    background-color: $dark-item-bg-color
  }
  .btn-group .btn.btn-default {
    @extend .thumbnail;
  }
  .modal-content{
    @extend .thumbnail;
  }
  select{
    @extend .thumbnail;
  }
  textarea{
    @extend .thumbnail;
  }
  input{
    @extend .thumbnail;
  }
  button{
    @extend .thumbnail;
  }


}


/* animation transition  */
$transition-duration: .5s;
@mixin transition($duration) {
  -webkit-transition: all $duration ease-in-out;
  -moz-transition: all $duration ease-in-out;
  -ms-transition: all $duration ease-in-out;
  -o-transition: all $duration ease-in-out;
  transition: all $duration ease-in-out;
}

body {
  @include transition($transition-duration);
  .thumbnail{
    @include transition($transition-duration);
  }
  .btn-group .btn.btn-default {
    @extend .thumbnail;
  }
  .modal-content{
    @extend .thumbnail;
  }
  select{
    @extend .thumbnail;
  }
  textarea{
    @extend .thumbnail;
  }
  input{
    @extend .thumbnail;
  }
  button{
    @extend .thumbnail;
  }
}


/*

 CANDIDAT - CARD

*/

.candidat-card{
  font-size: 1rem;
  height: 150px;
  margin: 20px 0px;

    .user-infos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100%;
        & > div {
            flex: 1 0 100%;
            padding-bottom: 5px;
        }
        & div:first-child { padding: 0px; }
        .langues {
            margin-top: 5px;
        }
    }


  .corner{
    position: absolute;
    right: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 0;
    border-color: transparent #bfbfbf transparent transparent;
    z-index: 2;
    &.red{
      border-color: transparent #d0021b transparent transparent;
    }
    &.green{
      border-color: transparent #b8e986 transparent transparent;
    }
  }

  .user-last_name {
    font-weight: bold;
    text-transform: uppercase;
  }
  .user-first_name {
    text-transform:capitalize;

  }
  .user-first_name::first-letter {
    text-transform:uppercase;
  }
  .user-first_name, .user-last_name, .user-birthdate{
    font-size: 1rem;
    line-height: 13pt;
  }
  .langues{
    font-size: .8rem;
    line-height: .8rem;
    color: #4a4a4a;
    .language:after{
      content:",";
    }
    .language:last-child:after{
        content:"";
    }
  }
  .candidature-number{
    text-align: right;
    text-align-last: right;
    font-size: 0.8rem;
    display: inline-block;
    min-width: 100%;
  }

  .candidature-infos-icon{
    // height: 60px;
    // line-height: 90px;
    position: relative;
    margin-top: 50px;
    right: 10px;

    .glyphicon{
      font-size:18px;
      padding: 0px;
    }
  }

  .photo {
    overflow: hidden;
    a{
      img{
        height: 100%;
        max-height: 80px;
        max-width: none;
        width: auto;
        margin: 0px;
      }
    }
  }

  &.showing_details{
    .thumbnail{
      box-shadow: 0px 0px 10px 1px #5bc0de;
    }
  }

  .candidature-infos{
    text-transform: uppercase;
    font-size: 9px;
    line-height: 15px;
    text-indent: 5px;
    display: block;
    margin: 0;
    position: absolute;
    z-index: 2;
    box-shadow: none;
    height: 15px;
    background: #d8d8d8;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    left: -1px;
    right: -1px;
    bottom: 0px;
  }
  &.ghost{
    pointer-events: none;
    .photo {
      a{
        cursor: not-allowed;
      }
    }
    .preserve-info{
      position: relative;
      visibility: hidden;
    }
    .preserve-info:before{
      content: "";
      position: absolute;
      visibility: visible;
    }
    .hide-infos{
      visibility: hidden;
    }
    .bar{
      background: #4a4a4a;
      text-align: right;
      position: absolute;
      left: 0;
      color:white;
    }
    .user-birthdate{display: none}
  }
  .thumbnail{
    padding: 10px 0px;
    height: 100%;
    position: relative;
    border-radius: 0px;
    outline: 0px;
  }

  a{
    color:black;

  }
  h3{
    padding: 0;
    margin: 0;
  }
  img {
    width: 50px;
  }
  .oneline{
    width: 100%;
    overflow: hidden;
    white-space:nowrap;
  }
  .flag-icon{
    height: 15px;
    width: 30px;
  }
  .row > div{
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.candidatures{

  .inner-addon {
    position: relative;
  }
  .btn-group{
    text-transform: uppercase;

  }

  select{
    width:100%;
    margin: 5px 0px;
    border: 0px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.5);
    margin:0px;
    font-size: 18px;
    line-height: 50px;
    height: 50px;
    vertical-align: middle;
    text-align-last:center; /* chrome tricks */
    box-sizing: content-box;
    text-transform: uppercase;

    &.no-border{
      border-bottom: 0px solid black;
    }
    &:focus{
      outline:none;
      background-color: rgba(0,0,0,.05)
    }
  }
  /* style glyph */
  .inner-addon .glyphicon {
    position: absolute;
    padding: 10px;
    pointer-events: none;
  }

  /* align glyph */
  .left-addon .glyphicon  { left:  0px;}
  .right-addon .glyphicon { right: 0px;}

  /* add padding  */
  .left-addon input  { padding-left:  30px; }
  .right-addon input { padding-right: 30px; }

  textarea{
    width: 100%;
  }


  .form-group {
    display: inline-block;
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] {
      + .btn-group > label span {
        width: 20px;
        &:first-child {
            display: none;
        }
        &:last-child {
            display: inline-block;
        }
      }
      &:checked {
        + .btn-group > label span{
          &:first-child {
              display: inline-block;
          }
          &:last-child {
              display: none;
          }
        }
      }
    }
  }
}

.candidat_view{
  position: fixed;
  overflow-y: scroll;
  height: 80%;
  display: block;
  .candidat{


    .flag-icon{
      width: 100px;
      height: 100px;
    }

    .toggleHeight{
      cursor: pointer;
      height: 20px;
      overflow: hidden;
      &:hover{
        cursor: pointer;
        height: auto;
      }
    }
  }
}

.field{
  position: relative;
  margin-top:20px;
}

.student-app{
    padding: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    &.container-fluid {
      /* BUG */
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
    .exposant{
      font-size: .8em;
      vertical-align: super;
    }
    h1{
      margin-top: 0px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 26px;
      &:after{
          content:"";
          margin: 5px 0px;
          height:7px;
          width: 80px;
          background: black;
          display: block;
      }
    }
    h2{
      font-size: 20px;
    }
    blockquote{
      font-size: 1em;
    }
    .upload{
      position: absolute;
      left:0;
      top:0;
      width: 100%;
      height: 100%;
      min-height: 100vh;
      background: rgba(255,255,255,0.8);
      z-index: 5;
      text-align: center;
      .unit{
        font-size: 2em;
        display: block;
        position: sticky;
        width:100%;
        text-align: center;
        top: 45%;
        .progress-bar{
          background-color: #333;
        }
      }
      .status{
        position: sticky;
        display: block;
        text-align: center;
        top: 50%;
        clear: both;
      }
      .abort{
        position: sticky;
        display: block;        
        top: 55%;
        margin: auto;
        clear: both;
      }
      img{
        position: sticky;
        display: block;
        // height: 20%;
        width:50%;
        margin: 25%;
        top: 20%;
        // margin-left: -13%;
        // left: 50%;
      }
    }
    ul{
      padding-left: 5px;
      margin-left: 5px;

    }
    .bg-grey{
      background-color: #EEE;
      padding:40px;
      margin: 0px -40px;
    }
    .content {
      max-width: 330px;
      width:100%;
      margin-top: 50px;
      z-index:2;
      padding: 40px;
      box-shadow: 0px 0px 5px black;
      position: relative;
      background-color: rgba(255,255,255,0.8);
      p, li{
        line-height: 1.228571
      }
    .main-content{
      ul{
        margin: 5px 0px;
        li{
          span{
            vertical-align: top; /* */
          }
        }
      }
    }
      
      .mobile{
        .breadcrumb{
          position: static;
          ul{
            margin:0px;
          }
        }
      }
    }
    .btn{
        background: black;
        padding: 5px 10px;
        color:white;
        border-radius: 0px;
        &.green {
          background-color: #B8E986;
        }
        &.block{
          display: block;
        }
        &.btn-inv{
          background: none;
          border: 1px solid black;
          color:black;
        }
        &.photo-remove{
          margin: 10px 0px;
        }
        &.add{
          position: absolute;
          &.right{
            right: 0px;
            margin-top: 0px; /* 17px */
          }
        }
        +.btn{
           margin: 3px;
        }
        
    }
    .help-pane{
      position: absolute;
      top: -50px;
      left: -5px;
      width: 105%;
      padding: 30px;
      background: #d8d8d8;
      min-height: 100vh;
      height: 100%;
      z-index: 100;
      .close{
        background: url(../images/candidature/icons/cancel.svg) no-repeat center center;
        width: 20%;
        height: 20px;
        display: inline-block;
        opacity: 1;
        overflow: hidden;
        text-indent: -100px;
        &:hover{
          opacity: .8;
        }
      }

    }

    .field{
      position: relative;
      margin-top:20px;
    }

    .small-font{
      font-size: 0.8em;
    }

    input, select, textarea{
      width:100%;
      margin: 5px 0px;
      border: 0px;
      border-bottom: 3px solid black;
      margin:0px;
      padding-bottom: 5px;
      // text-indent: 4px;
      font-size: 18px;
      color:#4a4a4a;
      &.no-border{
        border-bottom: 0px solid black;
      }
      &:focus{
        outline:none;
        background-color: rgba(0,0,0,.05)
      }
      &.ng-invalid.ng-touched{
        background-color:none;
        ~label .field-state{
          background: #dc4154;
        }
      }
      &.ng-valid{
        ~label .field-state{
          background: #B8E986;
        }
      }
      &.ng-invalid-required{
        ~label .field-state{
          background: orange;
        }
      }
      &.date{
        background: transparent url(../images/candidature/icons/calendar.svg) no-repeat right;
        background-size: 20px;
      }
      &[type='checkbox'],&[type='radio'] {
        /*
         width: 30px;
         height: 30px;
         */
         width: auto;
         display: inline-block;
        &.one{
          margin: 20px;
          ~ label {
            vertical-align: top;
            text-align: left;
            display:inline-block;
            &.big{
            }
          }
        }
        &.check{
          visibility: hidden;
          position: absolute;
          + label{
            font-size: 12px;
            padding-left: 50px;
            text-transform: none;
            font-weight: bold;
            color: black;
            position: relative;
            &:before{
              content: " ";
              display: inline-block;
              position: absolute;
              top:50%;
              left: 0;
              margin-top: -20px;
              width: 40px;
              height: 40px;
              background: transparent url(../images/candidature/icons/check_false.svg) 0 0px no-repeat;
            }
          }
          &:checked + label:before {
              background: transparent url(../images/candidature/icons/check_true.svg) 0 0px no-repeat;
          }
        }
      }
      &.phone_code{
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='0' y='15' fill='gray' font-size='12' font-family='arial'>+</text></svg>");
          background-repeat: no-repeat;
          background-position: 2px 5px;
          padding-left: 12px;
      }
    } /* input, select, textarea */
    
    .help-block{
      text-align: center;
      margin: 0px;
      &.error{
        color: black;
        font-weight: normal;
        text-align: left;
        text-decoration: none;
        text-transform: none;
        background: #dc4154;
        /* rgba(208,27,1,.75); */
        padding: 5px 10px;
        margin: 5px 0px;
      }
      a {
        color:#CCC;
        text-decoration: underline;
      }
    }
    select{
      background: url(../images/candidature/icons/arrow_list.svg) no-repeat left;
      background-size: 20px;
      text-indent: 15px; /* 15 %*/
      text-align: center;
      -webkit-appearance: none;
      appearance:none;
      -moz-appearance:none !important; /* Firefox */
      -webkit-appearance:none;
      margin: 0;
    }
    textarea{
      height: 50px;
      font-size: 16px;
      max-width: 100%;
      background-color: #EEE;
      &:focus{
        height: auto;
      }
      &.ng-not-empty{
        height: auto;
      }
    }
    label.topborder{
      border-top: 3px solid black;
    }
    label {

      text-align: right;
      font-weight: normal;
      text-transform: uppercase;
      font-size: .8em;
      color:grey;
      display: inline-block;
      width: 100%;
      .field-state{
        width:7px;
        height: 7px;
        background: black;
        display: inline-block;
        vertical-align: top;
        margin-top: 5px;
        &.invalid{
          background: #dc4154;
        }
        &.valid{
          background: #B8E986;
        }
        &.required{
          background: orange;
        }
      }
    }
    .summary {
      h2{
        margin: 0px;
        font-size: 1.1em;
        margin:20px 0px 10px 0px;
      }
      h3{
        margin: 0px;
        font-size: 0.8em;
      }
      hr{
        margin: 20px 0px 5px 0px;
        height: 1px;
      }
      a{
        &:hover{
          text-decoration: none;
        }
        label{
          cursor: pointer;
        }
      }
      input{
        width:70%;
        display: inline-block;
        margin: 5px 0px;
        font-size: 0.9em;
        border: 0px;
        margin: 0px;
        text-overflow: ellipsis;
        &.hidden{
          opacity: 0;
          width: 0px;
          // display: inline-block !important;
          display: block;
          height: 0px;
        }
      }
      label{
        text-align: left;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 0.8em;
        margin: 0px;
        color:grey;
        display: inline-block;
        width: auto;
      }

      .edit{
        font-size: 0.8em;
        color: black;
      }
      .isvalid:before{
        display: inline-block;
        width: 10px;
        margin-top: 0px;
        font-weight: bold;
        position: absolute;
        left:-15px;
        top:0px;
      }

      input.ng-invalid + .isvalid:before {
        content: "x";
        color: #dc4154;
      }
      input.ng-valid + .isvalid:before {
        content: "✔";
        color: #84bb4d;
      }
      .field{
        margin-top: 10px;
      }
    }
    .confirmation{
      .text-danger{
        color:#dc4154;
        width: 70%;
        position: relative;
        &:after{
          content:"!";
          position: absolute;
          top: 0px;
          right: -40%;
          color:white;
          background: #dc4154;
          text-align: center;
          border-radius: 100px;
          font-size: 40px;
          width: 40px;
          vertical-align: middle;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .navigation{
      margin: 30px 0px;
      position: relative;
      #timer-countdown{
        position: absolute;
        top:-50px;
        width: 100%;
      }
      .prev:before{
        content:"◄";
        margin-right: 5px;
        font-size: 0.5em;
        vertical-align: middle;
      }

      .next{
          position: relative;
          width: 65%;
          right: 0px;
          &:after{
            content:"►";
            margin-left: 5px;
            font-size: 0.5em;
            vertical-align: middle;
          }
      }
      &.footer {
        text-align: right;
        margin: 10px 0 0 0;
      }
      &.header{
        position: absolute;
        margin: 0px 0px;
        top:-20px;
        right: 0px;
        height: auto;
        li{
          padding: 0px;
        }
        a{
          font-size: 0.8em;
          padding:0px 5px;
          color: #9B9B9B;
          text-shadow: 0px 0px 0px white;
        }
        #ref-candidature{
          position: absolute;
          width: 100%;
          top: 21px;
          text-align: right;
          font-size: 0.7em;
          color: #9B9B9B;
          padding: 0px 5px;
          .number{
            font-size: 1.2em;
            color:#000;
            font-weight: bold;
          }
        }
      }
      &.breadcrumb{
        position: absolute;
        background: none;
        z-index: 1;
        margin: 0px 0px;
        max-width: 350px;
        width: 100%;
        text-align: center;
        a{
          color: grey;
        }
        ul{
          margin-bottom: 40px;
        }
        li{
          width: 18%;
          position: relative;
          padding: 0px 0px;
          &:hover, &.current {
            .name{
              visibility: visible;
            }
            .loading{
              visibility: visible;
            }
          }
          .icon {
            width: 100%;
          }
          .name{
            visibility: hidden;
            position: absolute;
            text-align: center;
            pointer-events: none;
            font-size: 0.8em;
            width: 100%;
          }
          .loading{
            margin: 5px 0px 5px 0px;
            visibility: hidden;
            width:100%;
            background: #BFBFBF;
            height: 5px;
            position: relative;
            .bar{
              left: 0px;
              position: absolute;
              background: #4A4A4A;
              height: 5px;
            }
            &.completed .bar{
              background: #B8E986;
            }

          }
        }
      }
    }

    .data-writing{
        position: fixed;
        left: 10%;
        top:15px;
        font-size: 0.8em;
        box-shadow: 2px 1px 2px rgba(0,0,0,0.3);
        background: #B8E986;
        color: black;;
        border-radius: 10px;
        padding: 1px 10px;
        -webkit-transition: 0.25s linear all;
      	transition: 0.25s linear all;
      	opacity: 1;
        z-index: 100;
        &.ng-hide{
          opacity:0;
        }
        &.ng-hide{
          display: inline-block !important;
        }
    }
    .modal {
      position: fixed;
      top:0px;
      left: 0px;
      height : 100%;
      min-height: 100vh;
      width: 100%;
      background: rgba(255,255,255,0.8);
      .modal-content{
        top:50%;
        padding: 10px;
        height: auto;//200px;
        width: 50%;
        margin: -100px auto auto auto;
        vertical-align: middle;

      }

    }

    .photo-upload{
      width: 100%;
      height: 200px;
      margin: auto;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      background-color: #BBB;
    }
    .media-upload{
      padding: 10px 0px;
      line-height: 20px;
      position: relative;
      background: #f2f2f2;
      cursor: pointer;
      width: 100%;
      .upload-file{
        background: url(../images/candidature/icons/file-upload.svg) no-repeat center center;
        background-size: 20px 20px;
        height: 20px;
        display: block;
        &.photo{
          background-size: 30px 30px;
          height: 60px;
        }
      }
    }
    .item {
      padding: 10px 0px;
      background: #f2f2f2;
      line-height: 20px;
      position: relative;
      vertical-align: top;
      .link{
        background: url(../images/candidature/icons/file.svg)  no-repeat center center;
        background-size: 20px 20px;
        height: 20px;
        width: 20%;
        display: inline-block;
      }
      .language{
        height: 20px;
        width: 20%;
        display: inline-block;
      }
      .flag-icon{
        width: 20%;
        height: 20px;
        background-position: center;
        display: inline-block;
        line-height: 20px;
        vertical-align:top;
        mix-blend-mode: luminosity;
        &:hover{
          mix-blend-mode: normal;
        }
      }
      .name{
        width: 50%;
        text-align: center;
        display: inline-block;
        font-weight: bold;
        text-transform: uppercase;
        color:black;
        margin-top:0px;
        text-overflow: ellipsis;
        overflow: hidden;
        &.left{
          width: 80%;
        }
      }
      .trash{
        background: url(../images/candidature/icons/trash.svg) no-repeat center center;
        background-size: 20px 20px;
        width: 20%;
        height: 20px;
        display: inline-block;
        cursor: pointer;
        line-height: 20px;
        position: absolute;
        right: 0px;
        animation: shake 1s linear 1;
        &:hover{
          animation: shake 3s linear infinite;
        }

      }
      .cancel{
        background: url(../images/candidature/icons/cancel.svg) no-repeat center center;
        width: 20%;
        height: 20px;
        display: inline-block;
        cursor: pointer;
        position: absolute;
        right: 0px;
      }
      .delete-pane{
        padding: 10px 0px;
        line-height: 20px;
        background: #dc4154;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .trash{
          left: 0px;
          
        }
        .name{
          padding-left: 30%;
          width: 70%;
        }
        .cancel{

        }
      }
    }

    .item-list.active{
      .item-infos{
        display:block;
      }
      .item-summary{
        display:none;
      }
    }
    .item-list{
      padding-bottom: 10px;
      .item-infos
      {
        display: none;
      }
      .item-summary{
        background: grey;
        cursor: pointer;
        padding: 10px 10px;
      }

      .remove{
        margin: 10px 0px;
      }
    }


    .formElt{
      margin: 50px 0px;

    }

    .right-screen{
      height: 100vh;
      padding-right:0px;
      padding-left:0px;

      .bg{
        height: 100%;
        background-size: cover;
        opacity: 0.5;
        background-position-y: 33%;
        position: fixed;
        overflow: hidden;
        width: 100%;
        transition: all 0.5s ease-out;

      }
      .copyright{
        position: fixed;
        bottom: 10px;
        right: 50px;
        background-color: rgba(255,255,255,0.75);
        padding: 1px 20px;
        font-size: 12px;

      }
      .step-infos{
          color: black;
          top:23%;
          position: absolute;
          margin-left: 80px;
          padding-left: 20px;
          border-left: 5px solid rgb(0, 0, 0);
          .current{
            font-size: 64px;
            font-weight: bold;
            display: inline-block;
            line-height: 60px;
          }
          .total{
            font-size: 20px;
            display: inline-block;
            vertical-align: top;
          }
          .title{
            font-weight: bold;
            font-size: 28px;
            display: block;
          }
      }
    }
}


 span[ng-show*="error"] {
    color:#dc4154;
 }

 .disabled{
   opacity: 0.2
 }

.btn{
  &.has-error{
    opacity: 0.2;
    pointer-events:none;

  }
  &.green, &.grey{
    color: white;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
  &.green{
    background: #B8E986;
    color: #4A4A4A;
  }
  &.grey{
    background: grey;
  }
  small {
    text-transform: none;
    font-size:90%;
  }
}

input{
  &.small{
    font-size: 0.5em;
  }
  &.big{
    font-size: 1.5em;
  }
}

 label,
 input[type="submit"]
  {
   display: block;
 }

 .inline{
   display: inline-block;
 }
 .block{
   display: block;
   width: 100%;
 }
 .drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    width: 200px;
    height: 65px;
    text-align: center;
    padding-top: 25px;
    margin: 10px;
}
.dragover {
    border: 5px dashed #4a4a4a;
}

.print{
  display: none;
}


// large screen
@media only screen and (min-width: 1200px) {
  .student-app .content,
  .student-app .navigation.breadcrumb{
    max-width:470px;
  }
}


// print
@media print {
    .print{
      display: block;
    }

    .no-print, .no-print * {
        display: none !important;
    }

    .tab-content > .tab-pane {
        display: block !important; opacity: 1 !important;
        visibility: visible;
    }
}


/* Portrait */
@media only screen

  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .icon {
      background-position: center center;
      background-repeat: no-repeat;
      width: 100%;
      height: 20pt;
      display: inline-block;
    }
    .student-app {
      .navigation .next {
        position: relative;
        float: right;
        width: 65%;
      }

      
      .modal .modal-content{
        width: 80%;
        top:25%;
      }

      .navigation.breadcrumb li .icon {
        width: 44pt;
        height: 30pt;
      }
      .content {
        max-width: 100%;
        padding: 5% 5% 80px 15px;

        .navigation.header{
          left: 0;
          right: auto;
          font-size: .8em;
        }

        .mobile .breadcrumb {
                position: static;
                /* width: 100%; */
                max-width: none;
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 99;
                background-color: rgba(256,256,256,1);
                padding: 0;
                padding-bottom: 10px;
                border-radius: 0;

                ul {
                  padding: 5px;
                  width:100%;
                }
                li{
                  width:14%;
                  .icon{
                    width:80%;
                  }
                }

                .name {
                  text-transform: uppercase;
                }

          }
        .data-writing{
              position: fixed;
              width: 100%;
              top:0px;
              left:0;
              border-radius: 0px;
          }
      }

    }
    .col-xs-12 {
      /*padding: 0;*/
    }
}


/* Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    .student-app {
      .content {
        max-width: none;
      }
    }
}
